.page-section {
  min-width: 320px;
  max-width: 1920px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}


@media only screen and (min-width: 768px) {

  .page-section {
    padding-left: 40px;
    padding-right: 40px;
  }

}


@media only screen and (min-width: 1200px) {

  .page-section {
    padding-left: 80px;
    padding-right: 80px;
  }

}


@media only screen and (min-width: 1350px) {

  .page-section {
    padding-left: 104px;
    padding-right: 104px;
  }

}


@media only screen and (min-width: 1920px) {

  .page-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

}