
.card__button {
  background: #fff;
  color: #000;
  height: 40px;
  padding: 0;
  position: absolute;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__button:hover, 
.card__button:focus { background: #fff; }


.card__button_type_keyword {
  position: absolute;
  left: 16px;
  top: 16px;
  width: auto;
  font-size: 14px;
  line-height: 24px;
  padding: 0px 20px;
  text-transform: capitalize;
}

.card__button_type_save, 
.card__button_type_delete {
  width: 40px;
  top: 16px;
  right: 16px; 
}


.card__button-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
  color: #b6bcbf;
  transition: 
    color .35s ease-in-out,
    fill .35s ease-in-out; 
}

.card__button:hover .card__button-icon,
.card__button:focus .card__button-icon {
  color: #000;
}


@media only screen and (min-width: 550px) {

  .card__button_type_keyword {
    top: 8px;
    left: 8px; 
  }

  .card__button_type_save,
  .card__button_type_delete {
    top: 8px;
    right: 8px; 
  }

}


@media only screen and (min-width: 1024px) {

  .card__button_type_keyword {
    top: 16px;
    left: 16px; 
  }

  .card__button_type_save,
  .card__button_type_delete {
    top: 16px;
    right: 16px; 
  }

}


@media only screen and (min-width: 1200px) {

  .card__button_type_save,
  .card__button_type_delete {
    top: 24px;
    right: 24px; 
  }

  .card__button_type_keyword {
    top: 24px;
    left: 24px; 
  }

}