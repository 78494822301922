.form__label {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #2f71e5;
  width: 100%;
  margin: 0;
}

.form__field {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  width: 100%;
  padding: 8px 0 6px 0;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.form__field:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 1);  
}

.form__field::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.form__error_type_field {
  margin: 4px 0 6px 0;
  min-height: 32px;
}