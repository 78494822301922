
.saved-news-intro { 
  padding: 8px 16px 32px 16px; 
  color: #1a1b22;
  margin: 0 0 auto 0;
}

.saved-news-intro__title {
  opacity: 0.5;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
}

.saved-news-intro__count { 
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  margin: 16px 0 50px 0;
  max-width: 336px;
}

.saved-news-intro__keywords {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.saved-news-intro__keyword-list { display: inline; }

.saved-news-intro__keyword-item { 
  display: inline; 
  font-weight: 700;
}


.saved-news-intro__keyword-item:after { content:', '; }

.saved-news-intro__keyword-item:last-child:after { content: ''; }

.saved-news-intro__keyword-button { 
  text-transform: capitalize;
  font-size: 18px;
  line-height: 24px;
}

.saved-news-intro__keyword-button_more {
  display: inline;
  text-transform: lowercase;
}


@media only screen and (min-width: 768px) {

  .saved-news-intro { padding: 24px 40px 32px 40px; }

  .saved-news-intro__title { font-size: 14px; }

  .saved-news-intro__count { margin: 16px 0; }

}


@media only screen and (min-width: 1024px) {

  .saved-news-intro { padding: 40px 40px 56px 40px; }

  .saved-news-intro__title { font-size: 16px; }

  .saved-news-intro__count { 
    margin: 28px 0 30px 0;
    font-size: 40px;
    line-height: 46px;
    max-width: 530px;
  }

}


@media only screen and (min-width: 1200px) {

  .saved-news-intro { padding: 40px 80px 56px 80px; }

  .saved-news-intro__title { font-size: 18px; }

}


@media only screen and (min-width: 1350px) {

  .saved-news-intro { padding: 40px 104px 56px 104px; }

}


@media only screen and (min-width: 1920px) {

  .saved-news-intro { margin: 0 auto auto auto; }

}