.no-news-results {
  margin: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-news-results__image {
  width: 96px;
  height: 96px;
}

.no-news-results__heading {
  font-family: 'Roboto Slab', serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  margin: 24px 0 16px 0;
}

.no-news-results__heading_error {
  margin: 0 0 16px 0;
}

.no-news-results__p {
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  max-width: 240px;
  margin: 0;
}

.no-news-results__p_error {
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  max-width: 380px;
  margin: 0;
}