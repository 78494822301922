.menu__button {
  background: transparent;
  font-size: 18px;
  line-height: 56px;
  height: 56px;
  width: calc(100% - 32px);
  box-sizing: border-box;
  vertical-align: middle;
  background: transparent;
  border: 1px solid currentColor;
  padding: 0 17px;
  color: currentColor;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  margin: 16px 16px 0 16px;
  transition: 
    color 0.35s ease-in-out, 
    background-color 0.35s ease-in-out, 
    border-color 0.35s ease-in-out;
}

.menu__button:hover, 
.menu__button:focus {
  background: #fff;
  color: #777370;
}


.menu__button_scheme_light { border-color: #d1d2d6; }

.menu__button_scheme_light:hover, 
.menu__button_scheme_light:focus {
  background: #fff;
  color: #000;
  border-color: #000;
}

.menu__button:focus-visible, 
.menu__button_scheme_light:focus-visible {
  border-color: #e89d22;
}

.menu__icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-left: 15px;
}



@media only screen and (min-width: 768px) {

  .menu__button {
    font-size: 16px;
    line-height: 26px;
    height: 40px;
    top: -1px;
    border-radius: 20px;
    width: auto;
    margin: 0 0 0 30px;
  }

  .menu__button_signin { min-width: 176px; }

}


@media only screen and (min-width: 1024px) {

  .menu__button {
    font-size: 18px;
    line-height: 48px;
    height: 48px;
    top: -2px;
    border-radius: 24px;
  }

}