.about-author {
  padding: 32px 16px 128px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about-author__img {
  width: calc(100% - 16px);
  height: auto;
  max-width: 348px;
  max-height: 348px;
  border-radius: 50%;
}

.about-author__content { margin: 24px 0 0 0; }

.about-author__heading {
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  font-family: 'Roboto Slab', serif;
  margin: 0;
}

.about-author__p {
  font-size: 18px;
  line-height: 24px;
}

.about-author__p:last-child { margin: 0; }


@media only screen and (min-width: 768px) {

  .about-author { 
    padding: 40px; 
    flex-direction: row;
  }

  .about-author__img {
    width: 232px;
    height: 232px;
    align-self: center;
  }

  .about-author__content {
    margin: 0 0 0 32px; 
  }

  .about-author__heading {
    font-size: 34px;
    line-height: 40px;
  }

}


@media only screen and (min-width: 1024px) {

  .about-author { padding: 80px 104px; }

  .about-author__img {
    width: 348px;
    height: 348px;
  }

  .about-author__content { 
    max-width: 600px;
    margin: 0 0 0 56px; 
  }

  .about-author__heading {
    font-size: 40px;
    line-height: 46px;
    margin: 16px 0 0 0;
  }

}


@media only screen and (min-width: 1200px) {

  .about-author__img {
    width: 464px;
    height: 464px;
  }
  
  .about-author__heading { margin: 51px 0 0 0; }

}