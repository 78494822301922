.menu {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 25px 0 30px 0;
  border-radius: 0 0 16px 16px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 
    .35s opacity .35s ease-in-out,
    .35s background-color .35s ease-in-out,
    .5s top .35s ease-in-out,
    .35s visibility .35s ease-in-out;
}

.menu_open {
  top: 100%;
  opacity: 1;
  visibility: visible;
  background: #1a1b22;
  transition: 
    .35s opacity .35s ease-in-out,
    .35s background-color .35s ease-in-out,
    .35s top .35s ease-in-out;
}

.menu_scheme_light.menu_open { background: #fff; }


.menu__link {
  display: block;
  align-items: center;
  color: currentColor;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding: 10px 16px;
  margin: 0 0 14px 0;
}

.menu__link:hover, 
.menu__link:focus {
  color: currentColor;
}




@media only screen and (min-width: 768px) {

  .menu { 
    display: flex;
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0 0 0 auto;
    background: transparent;
    border-radius: 0;
    transition: none;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  .menu__link { 
    display: inline;
    padding: 0 20px;
    margin: 0 0 0 20px;
    font-size: 16px; 
    position: relative;
  }

  .menu__link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    bottom: -24px;
    left: 0;
    border-bottom: 3px solid transparent;
    transition: border-color .35s ease-in-out;
  }

  .menu__link:hover:after, 
  .menu__link:focus:after { border-bottom: 3px solid rgba(255, 255, 255, 0.3); }

  .menu__link_scheme_light:hover:after, 
  .menu__link_scheme_light:focus:after { border-bottom: 3px solid rgba(0, 0, 0, 0.2); }

  .menu__link_current:after { border-bottom: 3px solid currentColor; }

  .menu__link_current:hover:after,
  .menu__link_current:focus:after { border-bottom: 3px solid currentColor; }

}


@media only screen and (min-width: 1024px) {

  .menu__link { 
    height: 80px;
    font-size: 18px; 
  }

  .menu__link:after {
    bottom: -30px;
  }

}
