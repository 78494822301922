.card {
  display: flex;
  flex-direction: column;
  position: relative;
}

.card__image {
  display: block;
  width: 100%;
  height: 61.25vw;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.card__article {
  background: #fff;
  margin: 0;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.card__title {
  font-family: 'Roboto Slab', serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.card__date {
  color: #b6bcbf;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 10px 0;
  display: block;
}

.card__link {
  color: #1a1b22;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 16px;
}

.card__link:hover,
.card__link:focus { outline: none; }

.card__link:focus-visible { box-shadow: 0 0 0 2px #e89d22; }

.card__description {
  font-size: 16px;
  line-height: 22px;
  margin: 14px 0 8px 0;
}

.card__source {
  color: #b6bcbf;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
  margin: 0;
}


@media only screen and (min-width: 400px) {

  .card__image { height: 50vw; }

}


@media only screen and (min-width: 550px) {

  .card__image { height: 30vw; }

}


@media only screen and (min-width: 768px) {

  .card__image { height: 18.89vw; }

  .card__date { margin: 0 0 12px 0; }

  .card__description { margin: 10px 0 12px 0; }

}


@media only screen and (min-width: 1200px) {

  .card__article { padding: 24px; }

  .card__date { font-size: 18px; }

  .card__title {
    font-size: 26px;
    line-height: 30px;
  }

  .card__description { margin: 16px 0 18px 0; }

}