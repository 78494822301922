.logo {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 16px;
  z-index: 3;
}

.logo__link {
  color: currentColor;
}

.logo__link:hover,
.logo__link:focus { color: currentColor; }


@media only screen and (min-width: 768px) {

  .logo { font-size: 20px; }

}