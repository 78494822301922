.show-more {
  background: #fff;
  color: #1a1b22;
  transition: opacity 0.35s ease-in-out;
  margin: 16px 0 6px 0;
}

.show-more:hover,
.show-more:focus { 
  background: #fff;
  opacity: 0.85; 
}


@media only screen and (min-width: 768px) {

  .show-more {
    width: 240px;
    margin: 32px 0 8px 0;
  }

}


@media only screen and (min-width: 1024px) {

  .show-more {
    width: 288px;
    margin: 64px 0 0 0; 
  }

}