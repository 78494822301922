.menu-toggle {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 0 auto;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  background: none;
  border: none;
  outline: none;
  z-index: 3;
}

.menu-toggle__icon {
  width: 24px;
  height: 24px;
  display: block;
  pointer-events: none;
}


@media only screen and (min-width: 768px) {

  .menu-toggle { display: none; } 

}